import React from 'react'
import { styled } from 'linaria/react'
import SiteButton from '../../../Core/SiteButton'
import { NavArrowRight } from 'iconoir-react'
import { theme } from '../../../Theme'

const ArticlesHeaderContainer = styled.div`
   h2 {
      font-size: calc(1vw + 1.2rem);
   }

   .btn-text-visibility {
      display: none;
      ${theme.above.xs} {
         display: inline-block;
      }
   }
`

const ArticlesHeader = ({ title, ctaText, ctaLink, ctaLinkNewWindow }) => {
   return (
      <ArticlesHeaderContainer className="row seperator d-flex align-items-center mb-32 pt-32">
         <div className="col-auto">
            <h2>{title}</h2>
         </div>
         <div className="col d-md-block">
            <hr />
         </div>
         {ctaLink && (
            <div className="col-auto">
               <SiteButton
                  className="py-8 px-16"
                  href={ctaLink}
                  target={ctaLinkNewWindow ? '_blank' : '_self'}
                  styleType="solid"
                  color="blue"
                  size="medium"
                  icon={true}
               >
                  <span className="btn-text-visibility">{ctaText}</span>
                  <NavArrowRight
                     strokeWidth={2.0}
                     style={{ marginLeft: '0.5rem' }}
                  ></NavArrowRight>
               </SiteButton>
            </div>
         )}
      </ArticlesHeaderContainer>
   )
}

export default ArticlesHeader
