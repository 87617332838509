import React from 'react'
import { styled } from 'linaria/react'
import SiteButton from '../SiteButton'
import { NavArrowRight } from 'iconoir-react'

const ComponentHeaderContainer = styled.div`
    h2 {
         font-size: calc(1vw + 1.2rem);
    }
`

const ComponentHeader = ({ title, ctaText, ctaLink, ctaLinkNewWindow }) => {
   return (
      <ComponentHeaderContainer className="row seperator d-flex align-items-center mb-32">
         <div className="col-auto">
            <h2>{title}</h2>
         </div>
         <div className="col d-none d-md-block">
            <hr />
         </div>
         <div className="col-auto d-none d-md-block">
            <SiteButton
               style={{ padding: '0.8rem 0.8rem 0.8rem 1.5rem' }}
               href={ctaLink}
               target={ctaLinkNewWindow ? "_blank" : "_self"}
               styleType="solid"
               color="blue"
               size="large"
               icon={true}
            >
               {ctaText}
               <NavArrowRight
                  strokeWidth={2.0}
                  style={{ marginLeft: '1rem' }}
               ></NavArrowRight>
            </SiteButton>
         </div>
      </ComponentHeaderContainer>
   )
}

export default ComponentHeader
         
         
