import React from 'react'
import Carousel from '../HelloRetail/Carousel'
import { getBoolByPropertyName } from '../Core/Util/Helpers'
import { useFetchProductsInOrder } from '../DigifiSearch/Hooks/useDigifiProductsInOrder'

function prepareArticleNumbersToFetch(products) {
   if (!Array.isArray(products)) return []

   const extractedArticleNumbersArr = products?.reduce(
      (accumulatedArticleNumbers, item) => {
         const artNr = item?.properties[0]?.value?.articleNumber
         if (artNr !== null && artNr !== undefined) {
            accumulatedArticleNumbers.push(artNr)
         }
         return accumulatedArticleNumbers
      },
      []
   )

   return extractedArticleNumbersArr
}

export const ManualRecommendation = ({ properties, products, hideHeader }) => {
   const carouselTitle = properties?.find(field => field?.name == 'title')?.value
      ?.string
   const hideHeaderFromCms = getBoolByPropertyName(properties, 'hideHeader')
   const shouldHideHeader = hideHeaderFromCms || hideHeader;
   const articleNumbersToFetch = prepareArticleNumbersToFetch(products);
   const { data: enhancedProducts, error, loading } = useFetchProductsInOrder(articleNumbersToFetch)

   if (!properties || !products ) {
      return <></>
   }

   return (
      <div className="retail-wrapper">
        <Carousel
            className="py-32 carousel-component"
            products={enhancedProducts}
            loading={loading}
            title={carouselTitle}
            properties={properties}
            hideHeader={shouldHideHeader}
         ></Carousel>
      </div>
   )
}
